const auth = {
  auth: "account:view",
}

export const UnrestrictedRoute = {
  Home: { path: '/' },
  AccessDenied: { path: '/accessDenied' }
}

/**
 * 
 * @deprecated 
 * !! DEPRECATED !!
 * @deprecated 
 * !! SLOWLY MOVE TO !! 
 * @file refactor-routes.js 
 * 
 */
export const Routes = {

  // auth
  login: { path: "/login" },
  changePasswordOverview: { path: "/changePassword/overview", autorize: "" },

  // Newsletter
  NewsLetterOverview: { path: 'newsletter/overview' },
  NewsLetterSentLogOverview: { path: 'newsletter/sentlog/overview' },
  NewsLetterSentLogDetail: { path: 'newsletter/sentlog/detail/:id' },
  NewsletterSetting: { path: 'newsletter/setting' },

  // homepage
  WelcomeOverview: { path: "/welcome/overview" },

  // Dashboard
  DashboardOverview: { path: "/dashboard/overview" },
  StatisticDashboard: { path: "/statistic" },

  // User management
  AccountOverview: { path: "/account/overview" },
  AllAccountOverview: { path: "/allaccount/overview" },

  ProfileOverview: { path: "/profile/overview" },
  AllProfileOverview: { path: "/allprofile/overview" },

  DetailProfileOverview: { path: "/profile/detail/:id" },
  EditProfileOverview: { path: "/profile/edit/:id" },

  ChangeBirthDateOverview: { path: "/changebirthdate/overview" },
  ProfileChangeRequestOverview: { path: "/profilechangerequest/overview" },
  DetailProfileChangeRequestOverview: { path: "/profilechangerequest/detail/:id" },

  ReportedProfileOverview: { path: "/reportedprofile/overview" },
  DetailReportedProfileOverview: { path: "/reported-content/profile/detail/:id" },

  IdentityVerificationOverview: { path: "/identityverification/overview" },
  DetailIdentityVerificationOverview: { path: "/identityverification/detail/:id" },
  updateIdentityVerificationOverview: { path: "/identityverification/update/:id" },
  EditIdentityVerificationOverview: { path: "/identityverification/edit/" },
  EditDetailIdentityVerificationOverview: { path: "/identityverification/edit/:id" },

  KYCOverview: { path: "/kyc/overview" },
  KYCDetail: { path: "/kyc/detail/:id" },
  KYCEdit: { path: "/kyc/edit/" },
  KYCEditDetail: { path: "/kyc/edit/:id" },

  ReferralList: { path: "/referrallist/overview" },
  ProfileTag: { path: "/profiletag/overview" },
  CreateProfileTag: { path: "/profiletag/create/overview" },
  DetailProfileTag: { path: "/profiletag/detail/:id" },
  EditProfileTag: { path: "/profiletag/edit/:id" },

  BillionaireKeywordSettings: { path: "/BillionareKeyword/Settings" },

  // content management
  ProductServicesOverview: { path: "/productservices/overview" },
  DetailProductServicesOverview: { path: "/productservices/detail/:id" },
  EditProductServicesOverview: { path: "/productservices/edit/:id" },
  ReportedServiceOverview: { path: "/reportedservice/overview" },
  DetailReportedServiceOverview: { path: "/reported-content/experience/detail/:id" },
  GroupListOverview: { path: "/grouplist/overview" },
  DetailGroupListOverview: { path: "/grouplist/detail/:id" },
  MessageGroupDetailOverview: { path: "/messagegroup/detail/:id" },
  ReportedGroupListOverview: { path: "/reportedgrouplist/overview" },
  DetailReportedGroupListOverview: { path: "/reported-content/group/detail/:id" },
  ReportedMessageDetailOverview: { path: "/reportedMessage/detail/:id" },
  EventListOverview: { path: "/eventlist/overview" },
  DetailEventListOverview: { path: "/eventlist/detail/:id" },
  EditEventListOverview: { path: "/eventlist/edit/:id" },
  ReportedEventListOverview: { path: "/reportedeventlist/overview" },
  DetailReportedEventListOverview: { path: "/reportedeventlist/detail/:id" },
  DetailReportedMiniEventDetail: { path: "/reported-content/mini-event/detail/:id" },
  EventTicketOverview: { path: "/eventticket/overview" },
  EventAttendeesOverview: { path: "/eventattendees/overview" },
  PlacesOverview: { path: "/places/overview" },
  DetailPlaces: { path: "/places/detail/:id" },
  EditPlaces: { path: "/places/edit/:id" },
  DetailPeople: { path: "/people/detail/:id" },
  ReportedPlacesOverview: { path: "/reportedplaces/overview" },
  DetailReportedPlacesOverview: { path: "/reported-content/place/detail/:id" },
  WishlistUser: { path: "/wishlistuser/overview" },
  DetailWishlistUser: { path: "/wishlist/detail/:id" },
  WishlistService: { path: "/wishlistservice/overview" },
  WishlistEvent: { path: "/wishlistevent/overview" },
  WishlistGroup: { path: "/wishlistgroup/overview" },
  WishlistPlace: { path: "/wishlistplace/overview" },
  BoughtWishlist: { path: "/boughtwishlist/overview" },
  Discoveroverview: { path: "/discover/overview" },
  CreateDiscoverOverview: { path: "/discover/create/overview" },
  DetailDiscoverOverview: { path: "/discover/detail/:id" },
  EditDiscoverOverview: { path: "/discover/edit/:id" },
  ViewSubmissionsOverview: { path: "/viewsubmissions/overview" },
  DetailViewSubmissionsOverview: { path: "/viewsubmissions/detail/:id" },
  FeatureGroupOverview: { path: "/featuregroup/overview" },
  AddFeatureGroupOverview: { path: "/featuregroup/addfeaturegroup/overview" },
  FeaturePlaceOverview: { path: "/featureplace/overview" },
  AddFeaturePlaceOverview: { path: "/featureplace/addfeatureplace/overview" },
  FeatureServiceOverview: { path: "/featureservice/overview" },
  AddFeatureServiceOverview: { path: "/featureservice/addfeatureservice/overview" },
  FeatureEventOverview: { path: "/featureevent/overview" },
  AddFeatureEventOverview: { path: "/featureevent/addfeatureevent/overview" },
  FeatureComunityOverview: { path: "/featurecommunity/overview" },
  AddFeatureComunityOverview: { path: "/featurecommunity/addfeaturecommunity/overview" },
  ArticleCategorie: { path: "articlecategory/overview" },

  FeatureDiscoverExpOverview: { path: "/featureDiscoverExp/overview" },
  AddFeatureDiscoverExpOverview: { path: "/featurecommunity/addfeatureDiscoverExp/overview" },

  UtmHistory: { path: "/utmhistory/overview" },
  UtmHistorySignUp: { path: "/utmhistorysignup/overview" },

  CategorySelectionEvents: { path: "/categoryselectionevents/overview" },
  CreateCategorySelectionEvents: { path: "/categoryselectionevents/create/overview" },
  DetailCategorySelectionEvents: { path: "/categoryselectionevents/detail/:id" },
  EditCategorySelectionEvents: { path: "/categoryselectionevents/edit/:id" },
  CategorySelectionExperience: { path: "/categoryselectionexperience/overview" },
  CreateCategorySelectionExperience: { path: "/categoryselectionexperience/create/overview" },
  DetailCategorySelectionExperience: { path: "/categoryselectionexperience/detail/:id" },
  EditCategorySelectionExperience: { path: "/categoryselectionexperience/edit/:id" },

  CategorySelectionPlace: { path: "/categoryselectionplace/overview" },
  CreateCategorySelectionPlace: { path: "/categoryselectionplace/create/overview" },
  DetailCategorySelectionPlace: { path: "/categoryselectionplace/detail/:id" },
  EditCategorySelectionPlace: { path: "/categoryselectionplace/edit/:id" },

  CategorySelectionYouHost: { path: "/categoryselectionyouhost/overview" },
  CreateCategorySelectionYouHost: { path: "/categoryselectionyouhost/create/overview" },
  DetailCategorySelectionYouHost: { path: "/categoryselectionyouhost/detail/:id" },
  EditCategorySelectionYouHost: { path: "/categoryselectionyouhost/edit/:id" },


  ComunityOverview: { path: "/community/overview" },
  DetailComunityOverview: { path: "/community/detail/:id" },
  EditComunityOverview: { path: "/community/edit/:id" },
  ReportedComunityOverview: { path: "/reportedcommunity/overview" },
  DetailReportedComunityOverview: { path: "/reported-content/community/detail/:id" },
  ArticlesOverview: { path: "/articles/overview" },
  DetailArticlesOverview: { path: "/articles/detail/:id" },
  EditArticlesOverview: { path: "/articles/edit/:id" },
  ReportedArticlesOverview: { path: "/reportedarticles/overview" },
  DetailReportedArticlesOverview: { path: "/reported-content/article/detail/:id" },
  DetailReportedPostComunities: { path: "/reported-content/post/detail/:id" },
  DetailReportedPostMedia: { path: "/reported-content/media/detail/:id" },

  ComunityContent: { path: "/communitycontent/overview" },
  DetailPostComunity: { path: "/post/detail/:id" },
  DetailMediaComunity: { path: "/media/detail/:id" },

  ReportedContent: { path: "/reportedcontent/overview" },

  // mathced Content

  MatchedPeople: { path: "/matchedpeople/overview" },

  // MINI EVENTS CONTENT MANAGEMENT
  MiniEventOverview: { path: '/mini-event/overview' },
  MiniEventDispute: { path: '/mini-event/dispute' },
  MiniEventDetail: { path: "/mini-event/detail/:id" },

  // finance management
  PaymentRequest: { path: "/paymentrequest/overview" },
  YouPlusOverview: { path: "/youplus/overview" },
  WithdrawalRequest: { path: "/withdrawalrequest/overview" },
  TransactionHistory: { path: "/transactionhistory/overview" },
  DetailTransactionHistory: { path: "/transactionhistory/detail/:id" },
  DebitOverview: { path: "/debit/overview" },
  LiveUserOverview: { path: "/liveuser/overview" },
  SnapshootOverview: { path: "/snapshoot/overview" },
  ViewAllFeesOverview: { path: "/viewallfees/overview" },
  TotalFeesDayOverview: { path: "/totalfeesday/overview" },
  LockedAssetsOverview: { path: "/lockedassets/overview" },
  PaymentLinkOverview: { path: "/paymentlink/overview" },
  AddPaymentLinkOverview: { path: "/paymentlink/addpaymentlink/overview" },
  DetailPaymentLinkOverview: { path: "/paymentlink/detail/:id" },

  RoleOverview: { path: "/role/overview" },
  AddRoleOverview: { path: "/role/addrole/overview" },
  EditRoleOverview: { path: "/role/edit/:id" },
  UserOverview: { path: "/user/overview" },
  AddUserOverview: { path: "/user/adduser/overview" },
  EditUserOverview: { path: "/user/edituser/:id" },

  // Setting
  globalSettings: { path: "/globalSettings/overview" },
  versioningSettings: { path: "/versioningSettings/overview" },
  profileExemptionBuyerFees: { path: "/profileExemptionBuyerFees/overview" },
  offensiveWords: { path: "/offensiveWords/overview" },
  fxRate: { path: "/fxRate/overview" },
  DailyFxRate: { path: "/dailyfx/overview" },
  addFxRate: { path: "/fxRate/addFxRate/overview" },
  editFxRate: { path: "/fxRate/editFxRate/:id" },
  exchangeRate: { path: "/exchangeRate/overview" },
  editexchangeRate: { path: "/exchangeRate/editexchangeRate/:id" },

  subcription: { path: "/subcriptions" },
  addsubcription: { path: "/subcription/addsubcriptions/" },
  createSubcription: { path: "/subcription/create" },
  detailSubcription: { path: "/subcription/:id" },
  MatchingSetting: { path: "/matching-setting/overview" },


  addPlanSettings: { path: "/subcription/:id/plan" },
  editPlanSettings: { path: "/subcription/:id/plan/:idPlan" },

  editsubcription: { path: "/subcription/editsubcription/:id" },
  keywordSettings: { path: "/keywordSettings/overview" },


  banneroverview: { path: "/appbanner/overview" },
  addbanner: { path: "/appbanner/addbanner/overview" },
  editbanner: { path: "/appbanner/editbanner/:id" },
  announcementoverview: { path: "/announcement/overview" },
  addannouncement: { path: "/announcement/addannouncement/overview" },
  editannouncement: { path: "/announcement/editannouncement/:id" },
  detailannouncement: { path: "/announcement/detailannouncement/:id" },

  announcementAlertOverview: { path: "/announcementAlert/overview" },
  addAnnouncementAlert: { path: "/announcementAlert/addAnnouncementAlert/overview" },
  editAnnouncementAlert: { path: "/announcementAlert/editAnnouncementAlert/:id" },
  detailAnnouncementAlert: { path: "/announcementAlert/detailAnnouncementAlert/:id" },

  PromoCodeOverview: { path: "/promocode/overview" },
  AddPromoCodeOverview: { path: "/promocode/addpromocode/overview" },
  DetailPromoCodeOverview: { path: "/promocode/detail/:id" },
  EditPromoCodeOverview: { path: "/promocode/edit/:id" },

  ZodiacDailyOverview: { path: "zodiac/overview" },
  ZodiacDailyDetail: { path: "/detailzodiacdaily/:id" },
  ZodiacDailyEdit: { path: "/editzodiacdaily/:id" },

  HoroscopeDailyOverview: { path: "horoscope/overview" },
  HoroscopeDailyCreate: { path: "/addhoroscopedaily/overview" },
  HoroscopeDailyEdit: { path: "/edithoroscopedaily/:id" },
  HoroscopeDailyDetail: { path: "/detailhoroscopedaily/:id" },

  HoroscopeTitleOverview: { path: "horoscopetitle/overview" },

  BaziWeightOverview: { path: "bazidaily/overview" },
  BaziWeightCreate: { path: "/addbazidaily/overview/:lang" },
  BaziWeightEdit: { path: "/editbazidaily/:id/:lang" },

  BaziTitlesOverview: { path: "bazititles/overview" },

  LuckyCategoriesOverview: { path: "luckycategories/overview" },
  LuckyItemsOverview: { path: "luckitems/overview" },
  LuckyItemsImport: { path: "luckitems/import" },

  logHistory: { path: "/logHistory/overview" },

  // restriction
  ...UnrestrictedRoute
};
