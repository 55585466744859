import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog, faSignOutAlt, faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { Nav, Navbar, Dropdown } from "@themesberg/react-bootstrap";
import { useAuth } from "data/context/auth";
import { useNavigate } from "react-router-dom";

function Navbars(props) {
    const history = useNavigate();
    const { logout, user } = useAuth();

    const DetailAccount = () => {
        history("/changePassword/overview");
    };

    return (
        <Navbar
            variant="light"
            // expanded
            className="py-0 d-lg-block"
        >
            {/* <Container fluid className="px-0"> */}
            {/* <div className="d-flex justify-content-end w-100"> */}
            <div className="w-100 text-balck">
                <Nav className="w-100">
                    <Dropdown as={Nav.Item} className="w-100 text-black">
                        <Dropdown.Toggle as={Nav.Link} className="p-1 bg-white">
                            <div className="d-flex justify-content-between align-items-center text-dark px-2">
                                <span className="mb-0 font-small fw-bold"> {user?.full_name ?? "Admin"} <small>({(user?.role?.name ?? "Admin").toLowerCase()})</small> </span>
                                <FontAwesomeIcon icon={faCaretDown} style={{ color: "#7F8DA1" }} />
                            </div>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="user-dropdown dropdown-menu-left">
                            <Dropdown.Item className="fw-bold" onClick={DetailAccount}>
                                <FontAwesomeIcon icon={faCog} className="me-2" />
                                <small>Change Password</small>
                            </Dropdown.Item>
                            <Dropdown.Item className="fw-bold" onClick={logout}>
                                <FontAwesomeIcon icon={faSignOutAlt} className="text-danger me-2" />
                                <small>Logout</small>
                            </Dropdown.Item>
                            <Dropdown.Item className="fw-bold py-0">
                                <small className="text-muted text-center" style={{ fontSize: ".7rem", lineHeight: 0 }}>v{process.env.REACT_APP_VERSION}</small>
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </Nav>
            </div>
            {/* </Container> */}
        </Navbar>
    );
}

export default Navbars;
