import { useState, useEffect } from "react";
import SimpleBar from "simplebar-react";
import { useLocation } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOutAlt, faTimes, faSearch } from "@fortawesome/free-solid-svg-icons";
import { Nav, Badge, Image, Button, Navbar, Form, InputGroup } from "@themesberg/react-bootstrap";
import { Link } from "react-router-dom";
import { Routes } from "../routes";
import Logo from "assets/img/logo.png";
import TextDivider from "../composes/TextDivider";
import { useAuth } from "data/context/auth";
import { MenuPage } from "constants/menu_pages";
import Navbars from "./Navbar";

const NavItem = (props) => {
    const { title, link, external, target, icon, image, badgeText, badgeBg = "secondary", badgeColor = "primary", autorezed = "", hidden = true, } = props;
    const location = useLocation();
    const { pathname } = location;
    const [show, setShow] = useState(false);

    // tambahkan kondisi untuk mengubah tampilan NavItem ketika hidden
    if (!hidden) {
        return null;
    }
    const classNames = badgeText ? "d-flex justify-content-start align-items-center justify-content-between" : "";
    const navItemClassName = pathname.includes(link) ? "active" : "";
    const linkProps = external ? { href: link } : { as: Link, to: link };

    return (
        <Nav.Item
            className={navItemClassName}
            onClick={() => setShow(false)}
            style={{ border: "none" }}
        >
            <Nav.Link
                {...linkProps}
                target={target}
                className={`${classNames}`}
                style={{
                    fontSize: 12,
                    paddingBlock: "5px",
                    border: "none",
                    whiteSpace: "normal",
                    wordBreak: "break-word",
                }}
            >
                {icon ? (
                    <span className="sidebar-icon">
                        <FontAwesomeIcon icon={icon} />{" "}
                    </span>
                ) : null}
                {image ? (
                    <Image src={image} className="sidebar-icon svg-icon" />
                ) : null}

                <span className="sidebar-text">{title}</span>
                {autorezed ? (
                    <span className="sidebar-autorezed">{autorezed}</span>
                ) : null}
                {badgeText ? (
                    <Badge
                        pill
                        bg={badgeBg}
                        text={badgeColor}
                        className="badge-md notification-count ms-2"
                    >
                        {badgeText}
                    </Badge>
                ) : null}
            </Nav.Link>
        </Nav.Item>
    );
};


const SideBar = (props = {}) => {
    const { user, autorizes, logout } = useAuth();
    const [show, setShow] = useState(false);
    const showClass = show ? "show" : "";

    const [routeMap, setRouteMap] = useState([])
    const [filterText, setFilterText] = useState("");

    const onCollapse = () => setShow(!show);

    useEffect(() => {
        setRouteMap([])
        const result = MenuPage(autorizes, user?.email ?? null)
            .filter((d) => d.sidebar && d?.hidden && d.hidden)
            .filter((item) => {
                return Object
                    .values(item)
                    .some((value) => value && typeof value === "string" && value.trim().toLowerCase().includes(filterText.toLowerCase()));
            })
            .reduce(function (r, a) {
                r[a.category] = r[a.category] || [];
                r[a.category].push(a);
                return r;
            }, Object.create(null));

        for (let value of Object.keys(result)) {
            if (result[value]?.hidden === undefined) setRouteMap(current => [...current, { sectionTitle: value || <>&nbsp;</> }])
            setRouteMap(current => [...current, ...result[value]])
        }
    }, [filterText, autorizes])

    useEffect(() => {
        const handleKeyPress = (event) => {
            // Check if Left Shift + Alt is pressed
            if (event.shiftKey && event.key === 'Alt') {
                const searchInput = document.getElementById('search-menu');
                // Focus on the input element with the id "search-menu"
                if (searchInput) {
                    searchInput.value = searchInput.value
                    searchInput.focus();
                }
            }
        };

        window.addEventListener('keydown', handleKeyPress);

        return () => {
            window.removeEventListener('keydown', handleKeyPress);
        };
    }, []);




    return (
        <>
            <Navbar
                expand={false}
                collapseOnSelect
                variant="dark"
                className="px-4 d-md-none"
                style={{ backgroundColor: "black" }}
            >
                <Navbar.Brand
                    className="me-lg-5"
                    as={Link}
                    to={Routes.DashboardOverview.path}
                >
                    <Image src={Logo} className="navbar-brand-light" />
                </Navbar.Brand>
                <Navbar.Toggle
                    as={Button}
                    aria-controls="main-navbar"
                    onClick={onCollapse}
                >
                    <span className="navbar-toggler-icon" />
                </Navbar.Toggle>
            </Navbar>

            <CSSTransition timeout={300} in={show} classNames="sidebar-transition">
                <SimpleBar
                    className={`collapse ${showClass} sidebar d-md-block text-white`}
                    style={{ backgroundColor: "black" }}
                >
                    <div className="sidebar-inner px-2 pt-3 mb-6 pb-4">
                        <div className="user-card d-flex d-md-none align-items-center justify-content-between justify-content-md-center">
                            {/* <div className="d-flex align-items-center">
                                
                                    <div className="user-avatar lg-avatar me-4">
                                        <Image src={ProfilePicture} className="card-img-top rounded-circle border-white" />
                                    </div>
                               

                                <div className="d-block">
                                    <h6 className="mb-0 font-small fw-bold">
                                        {user?.full_name ?? "Admin"} <small>({(user?.role?.name ?? "Admin").toLowerCase()})</small>
                                    </h6>
                                    <Button
                                        as={Link}
                                        variant="secondary"
                                        size="xs"
                                        to={Routes.login.path}
                                        className="text-dark"
                                        onClick={logout}
                                    >
                                        <FontAwesomeIcon icon={faSignOutAlt} className="me-2" />{" "}
                                        Sign Out
                                    </Button>
                                </div>
                            </div> */}
                            <Nav.Link
                                className="collapse-close d-md-none"
                                onClick={onCollapse}
                            >
                                <FontAwesomeIcon icon={faTimes} />
                            </Nav.Link>
                        </div>

                        <Nav className="flex-column pt-3 pt-md-0">
                            <NavItem title="" image={Logo} />
                            <Navbars />

                            {/* <hr className="mt-3 mb-0 border-indigo" /> */}

                            <InputGroup size="sm" className="my-3">
                                <InputGroup.Text
                                    className="bg-transparent"
                                >
                                    <FontAwesomeIcon icon={faSearch} />
                                </InputGroup.Text>
                                <Form.Control
                                    type="search"
                                    placeholder="Search... (shift + alt)"
                                    className="bg-transparent"
                                    autoFocus={true}
                                    id="search-menu"
                                    title="click `shift + alt` to search menu"
                                    style={{ zIndex: 999999 }}
                                    onChange={(e) => setFilterText(e.target.value)}
                                />
                            </InputGroup>

                            {
                                (routeMap?.length === 0) && <small className="text-sm mx-auto text-muted">No results for <strong className="text-white">{filterText}</strong>.</small>
                            }
                            {
                                routeMap?.length > 0 ? routeMap?.map((e, i) => {
                                    return (
                                        <div key={i}>
                                            {
                                                (e?.sectionTitle && e?.sectionTitle !== undefined) && <TextDivider key={i} text={e?.sectionTitle} hidden={!e.hidden} />
                                            }

                                            {
                                                e.title && <NavItem
                                                    key={i + 1}
                                                    title={e.title}
                                                    link={e.path}
                                                // hidden={e.hidden}
                                                />
                                            }
                                        </div>
                                    )
                                }) : null
                            }
                        </Nav>
                    </div>
                </SimpleBar>
            </CSSTransition>
        </>
    );
};


export default SideBar