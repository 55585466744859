import Axios from "axios";
import Cookies from "js-cookie";
import { LocalCookies } from "features/global/data/localCookies";
import moment from "moment-timezone";

const getToken = () => Cookies.get("token");

// running service
const hostLocal = "localhost:3030"
const hostDev = "dev-frontend.youapp.ai"
const hostUAT = "frontadmin-uat.youapp.ai:88"
const hostPrePROD = "testflight.youapp.ai:88"
const hostPROD = "fe-admin.youapp.ai"
const pinggirJurang = "ecd7-103-125-48-74.ngrok-free.app"

// API BE
const urls = {
  // local: "https://uat-admin.youapp.ai/",
  // pinggirJurang: "https://e454-103-125-48-74.ngrok-free.app",
  local: "https://uat-admin.youapp.ai/",
  dev: "https://dev-admin.youapp.ai/",
  uat: "https://uat-admin.youapp.ai/",
  preProd: "https://testflight.youapp.ai:3002/",
  prod: "https://api-refactor.youapp.ai:3002/"
}

const detectHost = () => {
  const host = window.location.host
  let currentEnv = "local"
  if (host === hostLocal) {
    currentEnv = "dev"
    // console.info(`you running on ${currentEnv}`);
  } else if (host === hostDev) {
    currentEnv = "dev"
    // console.info(`you running on ${currentEnv}`);
  } else if (host === pinggirJurang) {
    currentEnv = "pinggirJurang"
    // console.info(`you running on ${currentEnv}`);
  } else if (host === hostUAT) {
    currentEnv = "uat"
    // console.info(`you running on ${currentEnv}`);
  } else if (host === hostPrePROD) {
    currentEnv = "preProd"
    // console.info(`you running on ${currentEnv}`);
  } else if (host === hostPROD) {
    currentEnv = "prod"
    // console.info(`you running on ${currentEnv}`);
  } else {
    if (window.location.pathname.includes("404")) return
    alert("Host not found!")
    window.location = "/404"
  }

  return urls[currentEnv]
}

const createApiInstance = () => {
  const stateApi = detectHost()
  const apiUrl = stateApi
  const api = Axios.create({
    baseURL: apiUrl,
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${getToken()}`,
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      "Access-route": window.location.pathname,
    },
  });

  api.interceptors.request.use((config) => {
    const accessToken = getToken();
    config.headers = Object.assign({
      "Platfrom-Timezone": moment.tz.guess(true),
      ...LocalCookies.filterCountry.header(),
      ...(accessToken ? { Authorization: `Bearer ${accessToken}` } : {})
    }, config.headers);

    return config;
  }, (error) => {
    return Promise.reject(error);
  });

  api.interceptors.response.use((response) => response, (error) => {
    const status = error.response?.status || 500;
    if (status === 401 && window.location.pathname !== "/login") {
      Cookies.remove("token");
      window.location = "/login"
    }

    return Promise.reject(error);
  });

  return api;
};

export default createApiInstance;
