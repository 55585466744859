import { useState, useEffect } from "react";
import createApiInstance from "../data/services/api";
import { useRequestManager } from "services/RequestManagerContext";
import axios from "axios";

// Custom hook for using Fetch API dynamically
export default function useFetch({ method = "POST", path, data = {} }) {
  const api = createApiInstance();
  const { registerRequest } = useRequestManager();
  const [startFetching, setStartFetching] = useState(false);
  const [loading, setLoading] = useState(true);
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (startFetching) fetchData();
  }, [startFetching, registerRequest]);

  const fetchData = async () => {
    setLoading(true);
    try {
      const result = await api.request({
        method,
        url: `/${path}`,
        data,
        // cancelToken: registerRequest(),
        onUploadProgress: (progressEvent) => {
          const progress = (progressEvent.loaded / progressEvent.total) * 50;
          setProgress(progress);
        },
        onDownloadProgress: (progressEvent) => {
          const progress =
            50 + (progressEvent.loaded / progressEvent.total) * 50;
          setProgress(progress);
        },
      });

      setResponse(result.data?.data ?? result.data);
    } catch (error) {
      if (!axios.isCancel(error)) setError(error);
    } finally {
      // setProgress(0)
      setStartFetching(false);
      setLoading(false);
    }
  };

  return {
    response,
    setStartFetching,
    setProgress,
    progress,
    loading,
    responseError: error,
  };
}
