
// import { useEffect, useMemo } from "react";
// import Navbar from "../../components/Navbar"
import Sidebar from "../Sidebar";
import { Outlet } from "react-router-dom";
import { CountryNavBar } from "features/global/components/countryNavBar";

export default function MainLayout() {
    // const location = useLocation();

    // const userAuth = JSON.parse(sessionStorage.getItem("user"))?.autorize;
    // const { id } = useParams()

    // const path = location.pathname

    // useEffect(() => {
    //     const authorize = MenuPage()?.find((val) => {
    //         if (id) return val.path?.includes(path.replace(`/${id}`, ""))
    //         return val.path?.includes(path)
    //     })?.auth;

    //     if (userAuth && !userAuth.includes(authorize)) { console.log("redirect......."); }
    //     // if(userAuth && !userAuth.includes(authorize)) return redirectToAccessDenied()
    // }, [path]);

    return (
        <>
            {/* <Preloader show={loaded ? false : true} /> */}
            {/* Sidebar */}
            <Sidebar />
            <main className="content">
                <CountryNavBar />
                {/* <Navbar /> */}
                {/* Content */}
                <Outlet />
                {/* <Footer toggleSettings={toggleSettings} showSettings={showSettings} /> */}
            </main>
        </>
    )
}

function redirectToAccessDenied() {
    window.location.replace('/access-denied');
}