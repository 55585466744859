import React from "react";
import "css/divider.css";

const TextDivider = ({ text, hidden  }) => {

  if (!hidden) {
    return null;
  }

  return (
    <div className="hrdivider mb-0">
      <hr />
      <span>{text}</span>
    </div>
  );
};

export default TextDivider;
