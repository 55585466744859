import { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import MainLayout from "components/layouts/main.layout";
import { routeList, routeKey } from "refactor_routes";

export default function MainRoute() {

    const generateRoute = (route) => {
        return route.reduce((prev, curr) => {
            if (!curr.path || !curr.pathFile)
                return prev
            const Component = lazy(() => import(`../${curr.pathFile}.js`).catch(() => { return import(`../${curr.pathFile}/index.js`) }));
            prev = [...prev, <Route key={curr.key} path={curr.path} element={<Suspense fallback={<div>Loading...</div>}><Component /></Suspense>} />]
            return prev
        }, [])
    }

    return (
        <Suspense fallback={<div>Loading...</div>}>
            <Routes>
                <Route path="/" element={<MainLayout />}>
                    {
                        generateRoute(routeList({ except: [routeKey().login, routeKey().StatisticDashboard, routeKey().notFound] }))
                    }
                </Route>
                {
                    generateRoute(routeList({ only: [routeKey().login, routeKey().StatisticDashboard, routeKey().notFound] }))
                }
            </Routes>
        </Suspense >
    )
}

// import { lazy, Suspense } from "react";

// import { Route, Routes } from "react-router-dom";
// import MainLayout from "components/layouts/main.layout";
// import { allRoute, routeKey } from "refactor_routes";

// import Login from "pages/Login/login";

// import { Routes as routes } from '../routes';
// import StatisticDashboard from "pages/Statistic";
// import Welcome from "pages/MasterAdmin/Welcome";
// // import Dashboard from "pages/dashboard/DashboardOverview";
// // import AccountOverview from "pages/account/AccountOverview";
// // import ProfileOverview from "pages/account/ProfileOverview";
// import ReportProfile from "pages/account/ReportedProfile";
// import IdentityVerification from "pages/account/IdentityVerification";
// import EditIdentityVerification from "pages/account/EditIdentityVerification";
// import KYCOverview from "pages/account/KYCOverview";
// import KYCEdit from "pages/account/editKYC";
// import ReferralList from "pages/account/ReferralList";
// import ServicesOverview from "pages/contentManagement/Services";
// import ReportedService from "pages/contentManagement/ReportedService";
// import GroupOverview from "pages/contentManagement/Group";
// import ReportedGroup from "pages/contentManagement/ReportedGroup";
// import EventOverview from "pages/contentManagement/Event";
// import EventAttendesOverview from "pages/contentManagement/EventAttendees";
// import ReportedEvent from "pages/contentManagement/ReportedEvent";
// import EventTicket from "pages/contentManagement/EventTicket";
// import PaymentRequest from "pages/Finance/PaymentRequest";
// import WithdrawalOverview from "pages/Finance/WithdrawlRequest";
// import TransactionHistory from "pages/Finance/TransactionHistory";
// import DebitOverview from "pages/Finance/DebitAssets";
// import LiveUserOverview from "pages/Finance/LiveUserAssets";
// import SnapshootOverview from "pages/Finance/SnapshootAsset";
// import ViewAllFeesOverview from "pages/Finance/ViewAllFees";
// import TotalFeesDayOverview from "pages/Finance/TotalFeesPerDay";
// import LockedAssetsOverview from "pages/Finance/LockedAssets";
// import RoleOverview from "pages/MasterAdmin/RoleOverview";
// import Users from "pages/MasterAdmin/UserAdmin";
// import FXOverview from "pages/Setting/FxRate";
// import DailyFXOverview from "pages/Setting/DailyFxRate";
// import ExchangeRate from "pages/Setting/ExchangeRate";
// import OffensiveWords from "pages/Setting/OffensiveWords";
// import AnnouncementOverview from "pages/Setting/Announcement";
// import BannerOverview from "pages/Setting/Banner";
// import LogHistory from "pages/Log";
// import DetailProfile from "pages/account/detailProfile";
// import DetailReportedProfile from "pages/account/detailReportedProfile";
// import IdentityDetail from "pages/account/identity/detail";
// import KYCDetail from "pages/account/detailKYC";
// import EditKYC from "pages/account/editKYC/detail";
// import EditIdentitys from "pages/account/identity/edit(s)";
// import DetailServices from "pages/contentManagement/detailServices";
// import DetailReportedService from "pages/contentManagement/detailReportedService";
// import DetailGroup from "pages/contentManagement/detailGroup";
// import DetailReportedGroup from "pages/contentManagement/detailReportedGroup";
// import DetailEvent from "pages/contentManagement/detailEvent";
// import EditEvent from "pages/contentManagement/detailEvent/edit";
// import DetailReportedEvent from "pages/contentManagement/detailReportedEvent";
// import DetailTransaction from "pages/Finance/DetailTransactionHistory";
// import AddRole from "pages/MasterAdmin/form/add";
// import EditRole from "pages/MasterAdmin/form/edit";
// // import AddUser from "pages/MasterAdmin/user/add";
// // import EditUser from "pages/MasterAdmin/user/edit";
// import AddRate from "pages/Setting/fxrate/add";
// import EditRate from "pages/Setting/fxrate/edit";
// import ExchangeEdit from "pages/Setting/exchange/edit";
// import CreatedBanner from "pages/Setting/banner/add";
// import EditBanner from "pages/Setting/banner/edit";
// import AddAnnouncement from "pages/Setting/announcement/add";
// import EditAnnouncement from "pages/Setting/announcement/edit";
// import ChangePasswords from "pages/Login/changePassword";
// import DetailMessageGroup from "pages/contentManagement/detailGroup/DetailMessage";
// import DetailMessageReportedGroup from "pages/contentManagement/detailReportedGroup/DetailMessage";
// import DetailAnnouncement from "pages/Setting/announcement/index";
// import EditService from "pages/contentManagement/detailServices/edit";
// import PlacesOverview from "pages/contentManagement/Places";
// import DetailPlaces from "pages/contentManagement/detailPlaces";
// import FeatureGroupsOverview from "pages/contentManagement/FeatureGroups";
// import EditPlaces from "pages/contentManagement/detailPlaces/edit";
// import NotFound from "pages/404";
// import WishlistUserOverview from "pages/contentManagement/WishlistUser";
// import DetailWishlist from "pages/contentManagement/detailWishlist";
// import WishlistServiceOverview from "pages/contentManagement/WishlistService";
// import WishlistEventOverview from "pages/contentManagement/WishlistEvent";
// import WishlistGroupsOverview from "pages/contentManagement/WishlistPaidGroups";
// import WishlistPlacessOverview from "pages/contentManagement/WishlistPlaces";
// import EditProfile from "pages/account/detailProfile/edit";
// import WishlistBoughtOverview from "pages/contentManagement/BoughtWishlist";
// import PaymentLinkOverview from "pages/Finance/PaymentLink";
// import AddpaymentLink from "pages/Finance/Payment/create";
// import DetailPaymentLink from "pages/Finance/Payment/detail";
// import ReportedPlaces from "pages/contentManagement/ReportedPlaces";
// import DetailReportedPlaces from "pages/contentManagement/detailReportedPlaces";
// import PromoCodeOverview from "pages/Setting/PromoCode";
// import CreatePromoCode from "pages/Setting/PromoCode/Create";
// import DetailPromoCode from "pages/Setting/PromoCode/index";
// import EditPromocode from "pages/Setting/PromoCode/Edit";
// import DiscoverVideo from "pages/contentManagement/DiscoverVideo";
// import DetailVisited from "pages/contentManagement/detailPlaces/detailvisited";
// import CreateDiscoverVideo from "pages/contentManagement/detailDiscover/create";
// import UtmHistory from "pages/contentManagement/UtmHistory";
// import FeaturePlacesOverview from "pages/contentManagement/FeaturePlaces";
// import AddFeautreGroups from "pages/contentManagement/featureGroups/index";
// import AddFeaturePlaces from "pages/contentManagement/featurePlaces/index";
// import DetailDiscovervideo from "pages/contentManagement/detailDiscover";
// import EditDiscoverVideo from "pages/contentManagement/detailDiscover/edit";
// import CategorySelectionEvents from "pages/contentManagement/CategorySelectorEvents";
// import AddCategoryEvent from "pages/contentManagement/selectorCategoryEvent/create";
// import DetailCategorySelectorEvent from "pages/contentManagement/selectorCategoryEvent";
// import EditCategoryEvent from "pages/contentManagement/selectorCategoryEvent/edit";
// import CategorySelectorExp from "pages/contentManagement/CategorySelectorExp";
// import AddCategoryExp from "pages/contentManagement/selectorCategoryExp/create";
// import EditCategoryExp from "pages/contentManagement/selectorCategoryExp/edit";
// import DetailCategorySelectionExperience from "pages/contentManagement/selectorCategoryExp";
// import ProfileExecptions from "pages/Setting/ProfileExecption";
// import ComunityOverview from "pages/contentManagement/Comunity";
// import ReportedComunity from "pages/contentManagement/ReportedComunity";
// import DetailComunity from "pages/contentManagement/detailComunity";
// import DetailReportedComunity from "pages/contentManagement/detailReportedComunity";
// import EditComunity from "pages/contentManagement/detailComunity/edit";
// import FeatureExpOverview from "pages/contentManagement/FeatureExp";
// import AddFeautreexp from "pages/contentManagement/featureExperience";
// import FeatureEventOverview from "pages/contentManagement/FeatureEvent";
// import AddFeautreevents from "pages/contentManagement/featureEvents";
// import FeatureComunityOverview from "pages/contentManagement/featureComunity";
// import AddFeautreComuniti from "pages/contentManagement/featureComunitys";
// import ProfiletagOverview from "pages/account/ProfileTag";
// import AddProfileTag from "pages/account/profileTags/add";
// import DetailProfileTag from "pages/account/profileTags";
// import ArticlesOverview from "pages/contentManagement/Articles";
// import DetailArticles from "pages/contentManagement/detailArticle";
// import EditArticle from "pages/contentManagement/detailArticle/edit";
// import CategorySelectorYouHost from "pages/contentManagement/CategorySelectorYouHost";
// import AddCategoryYouHost from "pages/contentManagement/selectorCategoryYouHost/create";
// import EditCategoryYouhost from "pages/contentManagement/selectorCategoryYouHost/edit";
// import DetailCategorySelectionYouhost from "pages/contentManagement/selectorCategoryYouHost";
// import ViewSubmission from "pages/contentManagement/ViewSubmission";
// import DetailSubmission from "pages/contentManagement/detailSubmission";
// import ArticleCategory from "pages/contentManagement/ArticleCategory";
// import CategorySelectorPlace from "pages/contentManagement/CategorySelectorPlaces";
// import AddCategoryPlace from "pages/contentManagement/selectorCategoryPlace/create";
// import EditCategoryPlace from "pages/contentManagement/selectorCategoryPlace/edit";
// import DetailCategorySelectionPlace from "pages/contentManagement/selectorCategoryPlace";
// import FeatureDiscoverExpOverview from "pages/contentManagement/FeatureDiscoverExp";
// import AddFeautreDiscoverexp from "pages/contentManagement/featureDiscoverExps";
// import ReportedArticles from "pages/contentManagement/ReportedArticles";
// import DetailReportedArticles from "pages/contentManagement/detailReportedArticles";
// import EditProfileTag from "pages/account/profileTags/edit";
// import ReportedContent from "pages/contentManagement/ReportedContent";
// import UtmSignups from "pages/contentManagement/UtmHistorySignups";
// import DetailReportedPostComunity from "pages/contentManagement/detailReportedPostComunity";
// import ContentComnity from "pages/contentManagement/ContentComunity";
// import DetailPostComunity from "pages/contentManagement/detailPost";
// import DetailMediaComunity from "pages/contentManagement/detailMedia";
// import BillionaireKeywordSetting from "pages/account/BillionaireKeywordSettings";
// import AddHoroscopeDaily from "pages/selfDiscover/horoscope/add";
// // import HoroscopeDaily from "pages/selfDiscover/HoroscopeDaily";
// import DetailHoroscopeDaily from "pages/selfDiscover/horoscope";
// import BaziDaily from "pages/selfDiscover/BaziDaily";
// import EditBaziOverview from "pages/selfDiscover/Ba-zi/edit";
// import BaziTitles from "pages/selfDiscover/Ba-zi/title";
// import HoroscopeTitles from "pages/selfDiscover/horoscope/title";
// import LuckyCategories from "pages/selfDiscover/LuckyCategories";
// import LuckyItems from "pages/selfDiscover/LuckyItems";
// import ImportLuckyItems from "pages/selfDiscover/ImportLuckyItems";
// import YouPlusSubscribed from "pages/Finance/YouPlusSubscripbed";
// // import AddSubcripton from "pages/Setting/subsriptions/add";
// import HoroscopeDaily from "pages/selfDiscover/HoroscopeDaily2";

// // import AddPlans from "pages/Setting/subsriptions/plans/add";
// import VersioningSetting from "pages/Setting/VersioningSettings";
// import RequestChangeBirthdate from "pages/account/ChangeBirthdateRequest";
// import MatchedPeople from "pages/matchingContentManagement/matchedPeople";
// import KeywordSetting from "pages/Setting/KeywordSettings";
// import AnnouncementAlert from "pages/Setting/AnnouncementAlert";
// import AddAnnouncementAlert from "pages/Setting/announcementAlert/add";
// import DetailAnnouncementAlert from "pages/Setting/announcementAlert/index";
// import ProfileChangeRequest from "pages/account/ProfileChangeRequest";
// import DetailProfileRequest from "pages/account/detailProfileChange";
// import ZodiacDaily from "pages/selfDiscover/ZodiacDaily";
// import DetailZodiacDaily from "pages/selfDiscover/zodiac";
// import EditZodiacDaily from "pages/selfDiscover/zodiac/edit";
// import DetailReportedMediaComunity from "pages/contentManagement/detailReportedMediaComunity";
// import NewsLetterOverview from "pages/newsletter/NewsLetterOverview";
// import NewsLetterSentLogOverview from "pages/newsletter/NewsLetterSentLogOverview";
// import NewsLetterSentLogDetail from "pages/newsletter/NewSletterSentLogDetail";
// import NewsletterSetting from "pages/newsletter/NewsLetterSetting";
// import MatchingSetting from "pages/Setting/MatchingSetting";
// import MiniEventOverview from "pages/MiniEventsContentManagement/MiniEventOverview";
// import MiniEventDetail from "pages/MiniEventsContentManagement/MiniEventDetail";
// import MiniEventDispute from "pages/MiniEventsContentManagement/MiniEventDispute";
// import DetailReportedMiniEvent from "pages/contentManagement/detailReportedMiniEvent";
// import AccessDeniedPage from "pages/AccessDeniedPage";
// import { AddUserPage } from "pages/MasterAdmin/user/AddUserPage";

// import GlobalSetting from "pages/globalSettings/index";
// import Subcription from "pages/subsriptions/index";
// import ManageSubcription from "pages/subsriptions/manageSubcription";
// import ManagePlans from "pages/subsriptions/plans/form";
// import { lazy } from "react";


// export default function MainRoute() {
//     return (
//         <Suspense fallback={<div>Loading...</div>}>
//             <Routes>
//                 <Route path="/" element={<MainLayout />}>
//                     {
//                         allRoute({ except: [routeKey().login, routeKey().StatisticDashboard, routeKey().notFound] }).map((value) => {
//                             const Component = lazy(() => import(`../${value.pathFile}.js`));
//                             return <Route key={value.key} path={value.path} element={<Suspense fallback={<div>Loading...</div>}><Component /></Suspense>} />
//                         })
//                     }
//                 </Route>
//                 {
//                     allRoute({ only: [routeKey().login, routeKey().StatisticDashboard, routeKey().notFound] }).map((value) => {
//                         const Component = lazy(() => import(`../${value.pathFile}.js`));
//                         return <Route key={value.key} path={value.path} element={<Suspense fallback={<div>Loading...</div>}><Component /></Suspense>} />
//                     })
//                 }
//             </Routes>
//         </Suspense >
//     )

//     // return (
//     //     <Suspense fallback={<div>Loading...</div>}>
//     //         <Routes>
//     //             <Route path="/" element={<MainLayout />}>
//     //                 <Route path={routes.WelcomeOverview.path} element={<Welcome />} />
//     //                 {/* <Route path={routes.DashboardOverview.path} element={<Dashboard />} /> */}

//     //                 {/* Newsletter */}
//     //                 <Route path={routes.NewsLetterOverview.path} element={<NewsLetterOverview />} />
//     //                 <Route path={routes.NewsLetterSentLogOverview.path} element={<NewsLetterSentLogOverview />} />
//     //                 <Route path={routes.NewsLetterSentLogDetail.path} element={<NewsLetterSentLogDetail />} />
//     //                 <Route path={routes.NewsletterSetting.path} element={<NewsletterSetting />} />

//     //                 {/* User management */}
//     //                 {/* <Route path={routes.AccountOverview.path} element={<AccountOverview />} /> */}
//     //                 {/* <Route path={routes.AllAccountOverview.path} element={<AllAccountOverview />} /> */}
//     //                 {/* <Route path={routes.ProfileOverview.path} element={<ProfileOverview />} /> */}

//     //                 {/* <Route path={routes.AllProfileOverview.path} element={<AllProfileOverview />} /> */}
//     //                 <Route path={routes.DetailProfileOverview.path} element={<DetailProfile />} />
//     //                 <Route path={routes.EditProfileOverview.path} element={<EditProfile />} />
//     //                 <Route path={routes.ChangeBirthDateOverview.path} element={<RequestChangeBirthdate />} />
//     //                 <Route path={routes.ProfileChangeRequestOverview.path} element={<ProfileChangeRequest />} />
//     //                 <Route path={routes.DetailProfileChangeRequestOverview.path} element={<DetailProfileRequest />} />


//     //                 <Route path={routes.ReportedProfileOverview.path} element={<ReportProfile />} />
//     //                 <Route path={routes.DetailReportedProfileOverview.path} element={<DetailReportedProfile />} />
//     //                 <Route path={routes.IdentityVerificationOverview.path} element={<IdentityVerification />} />
//     //                 <Route path={routes.DetailIdentityVerificationOverview.path} element={<IdentityDetail />} />
//     //                 <Route path={routes.EditIdentityVerificationOverview.path} element={<EditIdentityVerification />} />
//     //                 <Route path={routes.updateIdentityVerificationOverview.path} element={<EditIdentitys />} />
//     //                 <Route path={routes.KYCOverview.path} element={<KYCOverview />} />
//     //                 <Route path={routes.KYCDetail.path} element={<KYCDetail />} />
//     //                 <Route path={routes.KYCEdit.path} element={<KYCEdit />} />
//     //                 <Route path={routes.KYCEditDetail.path} element={<EditKYC />} />
//     //                 <Route path={routes.ReferralList.path} element={<ReferralList />} />
//     //                 <Route path={routes.ProfileTag.path} element={<ProfiletagOverview />} />
//     //                 <Route path={routes.CreateProfileTag.path} element={<AddProfileTag />} />
//     //                 <Route path={routes.DetailProfileTag.path} element={<DetailProfileTag />} />
//     //                 <Route path={routes.EditProfileTag.path} element={<EditProfileTag />} />

//     //                 <Route path={routes.BillionaireKeywordSettings.path} element={<BillionaireKeywordSetting />} />

//     //                 {/* Content managament */}
//     //                 <Route path={routes.ProductServicesOverview.path} element={<ServicesOverview />} />
//     //                 <Route path={routes.DetailProductServicesOverview.path} element={<DetailServices />} />
//     //                 <Route path={routes.EditProductServicesOverview.path} element={<EditService />} />
//     //                 <Route path={routes.ReportedServiceOverview.path} element={<ReportedService />} />
//     //                 <Route path={routes.DetailReportedServiceOverview.path} element={<DetailReportedService />} />
//     //                 <Route path={routes.GroupListOverview.path} element={<GroupOverview />} />
//     //                 <Route path={routes.DetailGroupListOverview.path} element={<DetailGroup />} />
//     //                 <Route path={routes.MessageGroupDetailOverview.path} element={<DetailMessageGroup />} />
//     //                 <Route path={routes.ReportedGroupListOverview.path} element={<ReportedGroup />} />
//     //                 <Route path={routes.DetailReportedGroupListOverview.path} element={<DetailReportedGroup />} />
//     //                 <Route path={routes.ReportedMessageDetailOverview.path} element={<DetailMessageReportedGroup />} />
//     //                 <Route path={routes.EventListOverview.path} element={<EventOverview />} />
//     //                 <Route path={routes.DetailEventListOverview.path} element={<DetailEvent />} />
//     //                 <Route path={routes.EditEventListOverview.path} element={<EditEvent />} />
//     //                 <Route path={routes.EventAttendeesOverview.path} element={<EventAttendesOverview />} />
//     //                 <Route path={routes.ReportedEventListOverview.path} element={<ReportedEvent />} />
//     //                 <Route path={routes.DetailReportedEventListOverview.path} element={<DetailReportedEvent />} />
//     //                 <Route path={routes.DetailReportedMiniEventDetail.path} element={<DetailReportedMiniEvent />} />
//     //                 <Route path={routes.EventTicketOverview.path} element={<EventTicket />} />
//     //                 <Route path={routes.PlacesOverview.path} element={<PlacesOverview />} />
//     //                 <Route path={routes.DetailPlaces.path} element={<DetailPlaces />} />
//     //                 <Route path={routes.EditPlaces.path} element={<EditPlaces />} />
//     //                 <Route path={routes.DetailPeople.path} element={<DetailVisited />} />
//     //                 <Route path={routes.ReportedPlacesOverview.path} element={<ReportedPlaces />} />
//     //                 <Route path={routes.DetailReportedPlacesOverview.path} element={<DetailReportedPlaces />} />
//     //                 <Route path={routes.FeatureGroupOverview.path} element={<FeatureGroupsOverview />} />
//     //                 <Route path={routes.AddFeatureGroupOverview.path} element={<AddFeautreGroups />} />
//     //                 <Route path={routes.FeaturePlaceOverview.path} element={<FeaturePlacesOverview />} />
//     //                 <Route path={routes.AddFeaturePlaceOverview.path} element={<AddFeaturePlaces />} />
//     //                 <Route path={routes.FeatureServiceOverview.path} element={<FeatureExpOverview />} />
//     //                 <Route path={routes.AddFeatureServiceOverview.path} element={<AddFeautreexp />} />
//     //                 <Route path={routes.FeatureEventOverview.path} element={<FeatureEventOverview />} />
//     //                 <Route path={routes.AddFeatureEventOverview.path} element={<AddFeautreevents />} />
//     //                 <Route path={routes.FeatureComunityOverview.path} element={<FeatureComunityOverview />} />
//     //                 <Route path={routes.AddFeatureComunityOverview.path} element={<AddFeautreComuniti />} />
//     //                 <Route path={routes.FeatureDiscoverExpOverview.path} element={<FeatureDiscoverExpOverview />} />
//     //                 <Route path={routes.AddFeatureDiscoverExpOverview.path} element={<AddFeautreDiscoverexp />} />


//     //                 <Route path={routes.WishlistUser.path} element={<WishlistUserOverview />} />
//     //                 <Route path={routes.DetailWishlistUser.path} element={<DetailWishlist />} />
//     //                 <Route path={routes.WishlistService.path} element={<WishlistServiceOverview />} />
//     //                 <Route path={routes.WishlistEvent.path} element={<WishlistEventOverview />} />
//     //                 <Route path={routes.WishlistGroup.path} element={<WishlistGroupsOverview />} />
//     //                 <Route path={routes.WishlistPlace.path} element={<WishlistPlacessOverview />} />
//     //                 <Route path={routes.BoughtWishlist.path} element={<WishlistBoughtOverview />} />
//     //                 <Route path={routes.Discoveroverview.path} element={<DiscoverVideo />} />
//     //                 <Route path={routes.CreateDiscoverOverview.path} element={<CreateDiscoverVideo />} />
//     //                 <Route path={routes.DetailDiscoverOverview.path} element={<DetailDiscovervideo />} />
//     //                 <Route path={routes.EditDiscoverOverview.path} element={<EditDiscoverVideo />} />
//     //                 <Route path={routes.ViewSubmissionsOverview.path} element={<ViewSubmission />} />
//     //                 <Route path={routes.DetailViewSubmissionsOverview.path} element={<DetailSubmission />} />
//     //                 <Route path={routes.UtmHistory.path} element={<UtmHistory />} />
//     //                 <Route path={routes.UtmHistorySignUp.path} element={<UtmSignups />} />
//     //                 <Route path={routes.CategorySelectionEvents.path} element={<CategorySelectionEvents />} />
//     //                 <Route path={routes.CreateCategorySelectionEvents.path} element={<AddCategoryEvent />} />
//     //                 <Route path={routes.DetailCategorySelectionEvents.path} element={<DetailCategorySelectorEvent />} />
//     //                 <Route path={routes.EditCategorySelectionEvents.path} element={<EditCategoryEvent />} />
//     //                 <Route path={routes.CategorySelectionExperience.path} element={<CategorySelectorExp />} />
//     //                 <Route path={routes.CreateCategorySelectionExperience.path} element={<AddCategoryExp />} />
//     //                 <Route path={routes.EditCategorySelectionExperience.path} element={<EditCategoryExp />} />
//     //                 <Route path={routes.DetailCategorySelectionExperience.path} element={<DetailCategorySelectionExperience />} />

//     //                 <Route path={routes.CategorySelectionYouHost.path} element={<CategorySelectorYouHost />} />
//     //                 <Route path={routes.CreateCategorySelectionYouHost.path} element={<AddCategoryYouHost />} />
//     //                 <Route path={routes.EditCategorySelectionYouHost.path} element={<EditCategoryYouhost />} />
//     //                 <Route path={routes.DetailCategorySelectionYouHost.path} element={<DetailCategorySelectionYouhost />} />

//     //                 <Route path={routes.CategorySelectionPlace.path} element={<CategorySelectorPlace />} />
//     //                 <Route path={routes.CreateCategorySelectionPlace.path} element={<AddCategoryPlace />} />
//     //                 <Route path={routes.EditCategorySelectionPlace.path} element={<EditCategoryPlace />} />
//     //                 <Route path={routes.DetailCategorySelectionPlace.path} element={<DetailCategorySelectionPlace />} />

//     //                 <Route path={routes.ComunityOverview.path} element={<ComunityOverview />} />
//     //                 <Route path={routes.DetailComunityOverview.path} element={<DetailComunity />} />
//     //                 <Route path={routes.EditComunityOverview.path} element={<EditComunity />} />
//     //                 <Route path={routes.ArticleCategorie.path} element={<ArticleCategory />} />
//     //                 <Route path={routes.ReportedComunityOverview.path} element={<ReportedComunity />} />
//     //                 <Route path={routes.DetailReportedComunityOverview.path} element={<DetailReportedComunity />} />
//     //                 <Route path={routes.ArticlesOverview.path} element={<ArticlesOverview />} />
//     //                 <Route path={routes.DetailArticlesOverview.path} element={<DetailArticles />} />
//     //                 <Route path={routes.EditArticlesOverview.path} element={<EditArticle />} />
//     //                 <Route path={routes.ReportedArticlesOverview.path} element={<ReportedArticles />} />
//     //                 <Route path={routes.DetailReportedArticlesOverview.path} element={<DetailReportedArticles />} />
//     //                 <Route path={routes.DetailReportedPostComunities.path} element={<DetailReportedPostComunity />} />
//     //                 <Route path={routes.DetailPostComunity.path} element={<DetailPostComunity />} />
//     //                 <Route path={routes.DetailMediaComunity.path} element={<DetailMediaComunity />} />
//     //                 <Route path={routes.DetailReportedPostMedia.path} element={<DetailReportedMediaComunity />} />



//     //                 <Route path={routes.ReportedContent.path} element={<ReportedContent />} />
//     //                 <Route path={routes.ComunityContent.path} element={<ContentComnity />} />

//     //                 {/* MATCHING CONTENT MANAGEMENT */}
//     //                 <Route path={routes.MatchedPeople.path} element={<MatchedPeople />} />

//     //                 {/* MINI EVENTS CONTENT MANAGEMENT */}
//     //                 <Route path={routes.MiniEventOverview.path} element={<MiniEventOverview />} />
//     //                 <Route path={routes.MiniEventDetail.path} element={<MiniEventDetail />} />
//     //                 <Route path={routes.MiniEventDispute.path} element={<MiniEventDispute />} />

//     //                 {/* Finance management */}
//     //                 <Route path={routes.PaymentRequest.path} element={<PaymentRequest />} />
//     //                 <Route path={routes.YouPlusOverview.path} element={<YouPlusSubscribed />} />
//     //                 <Route path={routes.WithdrawalRequest.path} element={<WithdrawalOverview />} />
//     //                 <Route path={routes.TransactionHistory.path} element={<TransactionHistory />} />
//     //                 <Route path={routes.DetailTransactionHistory.path} element={<DetailTransaction />} />
//     //                 <Route path={routes.DebitOverview.path} element={<DebitOverview />} />
//     //                 <Route path={routes.LiveUserOverview.path} element={<LiveUserOverview />} />
//     //                 <Route path={routes.SnapshootOverview.path} element={<SnapshootOverview />} />
//     //                 <Route path={routes.ViewAllFeesOverview.path} element={<ViewAllFeesOverview />} />
//     //                 <Route path={routes.TotalFeesDayOverview.path} element={<TotalFeesDayOverview />} />
//     //                 <Route path={routes.LockedAssetsOverview.path} element={<LockedAssetsOverview />} />
//     //                 <Route path={routes.PaymentLinkOverview.path} element={<PaymentLinkOverview />} />
//     //                 <Route path={routes.AddPaymentLinkOverview.path} element={<AddpaymentLink />} />
//     //                 <Route path={routes.DetailPaymentLinkOverview.path} element={<DetailPaymentLink />} />
//     //                 <Route path={routes.EditPromoCodeOverview.path} element={<EditPromocode />} />


//     //                 {/* Master admin */}
//     //                 <Route path={routes.RoleOverview.path} element={<RoleOverview />} />
//     //                 <Route path={routes.AddRoleOverview.path} element={<AddRole />} />
//     //                 <Route path={routes.EditRoleOverview.path} element={<EditRole />} />
//     //                 <Route path={routes.UserOverview.path} element={<Users />} />
//     //                 <Route path={routes.AddUserOverview.path} element={<AddUserPage />} />
//     //                 <Route path={routes.EditUserOverview.path} element={<AddUserPage />} />

//     //                 {/* Global setting */}
//     //                 <Route path={routes.fxRate.path} element={<FXOverview />} />
//     //                 <Route path={routes.DailyFxRate.path} element={<DailyFXOverview />} />
//     //                 <Route path={routes.addFxRate.path} element={<AddRate />} />
//     //                 <Route path={routes.editFxRate.path} element={<EditRate />} />
//     //                 <Route path={routes.exchangeRate.path} element={<ExchangeRate />} />
//     //                 <Route path={routes.editexchangeRate.path} element={<ExchangeEdit />} />
//     //                 <Route path={routes.globalSettings.path} element={<GlobalSetting />} />
//     //                 <Route path={routes.versioningSettings.path} element={<VersioningSetting />} />
//     //                 <Route path={routes.profileExemptionBuyerFees.path} element={<ProfileExecptions />} />
//     //                 <Route path={routes.offensiveWords.path} element={<OffensiveWords />} />
//     //                 <Route path={routes.banneroverview.path} element={<BannerOverview />} />
//     //                 <Route path={routes.addbanner.path} element={<CreatedBanner />} />
//     //                 <Route path={routes.editbanner.path} element={<EditBanner />} />
//     //                 <Route path={routes.announcementoverview.path} element={<AnnouncementOverview />} />
//     //                 <Route path={routes.addannouncement.path} element={<AddAnnouncement />} />
//     //                 <Route path={routes.editannouncement.path} element={<EditAnnouncement />} />
//     //                 <Route path={routes.detailannouncement.path} element={<DetailAnnouncement />} />
//     //                 <Route path={routes.MatchingSetting.path} element={<MatchingSetting />} />

//     //                 <Route path={routes.announcementAlertOverview.path} element={<AnnouncementAlert />} />
//     //                 <Route path={routes.addAnnouncementAlert.path} element={<AddAnnouncementAlert />} />
//     //                 <Route path={routes.editAnnouncementAlert.path} element={<AddAnnouncementAlert />} />
//     //                 <Route path={routes.detailAnnouncementAlert.path} element={<DetailAnnouncementAlert />} />



//     //                 <Route path={routes.PromoCodeOverview.path} element={<PromoCodeOverview />} />
//     //                 <Route path={routes.AddPromoCodeOverview.path} element={<CreatePromoCode />} />
//     //                 <Route path={routes.DetailPromoCodeOverview.path} element={<DetailPromoCode />} />

//     //                 <Route path={routes.subcription.path} element={<Subcription />} />
//     //                 {/* <Route path={routes.addsubcription.path} element={<AddSubcripton />} /> */}
//     //                 <Route path={routes.createSubcription.path} element={<ManageSubcription />} />
//     //                 <Route path={routes.detailSubcription.path} element={<ManageSubcription />} />

//     //                 <Route path={routes.addPlanSettings.path} element={<ManagePlans />} />
//     //                 <Route path={routes.editPlanSettings.path} element={<ManagePlans />} />
//     //                 {/* <Route path={routes.editsubcription.path} element={<AddSubcripton />} /> */}

//     //                 <Route path={routes.keywordSettings.path} element={<KeywordSetting />} />



//     //                 {/* Self discover */}
//     //                 <Route path={routes.ZodiacDailyOverview.path} element={<ZodiacDaily />} />
//     //                 <Route path={routes.ZodiacDailyDetail.path} element={<DetailZodiacDaily />} />
//     //                 <Route path={routes.ZodiacDailyEdit.path} element={<EditZodiacDaily />} />

//     //                 <Route path={routes.HoroscopeDailyOverview.path} element={<HoroscopeDaily />} />
//     //                 <Route path={routes.HoroscopeDailyCreate.path} element={<AddHoroscopeDaily />} />
//     //                 <Route path={routes.HoroscopeDailyEdit.path} element={<AddHoroscopeDaily />} />
//     //                 <Route path={routes.HoroscopeDailyDetail.path} element={<DetailHoroscopeDaily />} />
//     //                 <Route path={routes.HoroscopeTitleOverview.path} element={<HoroscopeTitles />} />


//     //                 <Route path={routes.BaziWeightOverview.path} element={<BaziDaily />} />
//     //                 <Route path={routes.BaziWeightCreate.path} element={<EditBaziOverview />} />
//     //                 <Route path={routes.BaziWeightEdit.path} element={<EditBaziOverview />} />
//     //                 <Route path={routes.BaziTitlesOverview.path} element={<BaziTitles />} />

//     //                 <Route path={routes.LuckyCategoriesOverview.path} element={<LuckyCategories />} />
//     //                 <Route path={routes.LuckyItemsOverview.path} element={<LuckyItems />} />
//     //                 <Route path={routes.LuckyItemsImport.path} element={<ImportLuckyItems />} />

//     //                 {/* Log */}
//     //                 <Route path={routes.logHistory.path} element={<LogHistory />} />
//     //                 <Route path={routes.changePasswordOverview.path} element={<ChangePasswords />} />

//     //                 {/* restriction */}
//     //                 <Route path={routes.AccessDenied.path} element={<AccessDeniedPage />} />


//     //             </Route>

//     //             <Route path={routes.login.path} element={<Login />} />

//     //             <Route path={routes.StatisticDashboard.path} element={<StatisticDashboard />} />
//     //             <Route path='*' element={<NotFound />} />
//     //         </Routes>
//     //     </Suspense>
//     // )
// }